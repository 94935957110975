
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BookingsApi } from '@/api/BookingsApi';
import { OrderActivityInterface } from '@/interfaces/Order';

import { mapState } from 'vuex';
import { first } from 'lodash';
import { downloadBlob } from 'download.js';
import b64toBlob from 'b64-to-blob';

import BasePage from '@/components/BasePage';

@Component({
   computed: {
      ...mapState({
         vat: (s: any) => s.activities.vat,
         currencies: (s: any) => s.activities.currencies,
         o_invoice_state: (s: any) => s.activities.invoice_states,
         beneficiaries: (s: any) => s.activities.beneficiaries,
      }),
   },
})
export default class ActionCreateInvoice extends BasePage {
   @Prop({ type: Object, default: () => ({}) }) form!: OrderActivityInterface;

   @Prop({ type: Object, default: () => ({}) }) currencyParams!: any;

   @Prop({ type: Number, default: 0 }) TotalAccommodationPrice!: number;

   vat!: any[];

   currencies!: any[];

   o_invoice_state!: any[];

   beneficiaries!: any[];

   o_invoice_fields: any[] = [
      {
         key: '0',
         label: 'SB ID',
         thAttr: {
            style: 'width: 85px;',
         },
      },
      {
         key: '1',
         label: 'Invoice number',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '2',
         label: 'Invoice date',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '3',
         label: 'Reference',
      },
      {
         key: '4',
         label: 'Invoice amount',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '5',
         label: 'Invoice currency',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '6',
         label: 'Paid amount',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '7',
         label: 'Currency',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '8',
         label: 'State',
         thAttr: {
            style: 'width: 100px;',
         },
      },
      {
         key: '9',
         label: '',
         thAttr: {
            style: 'width: 20px; vertical-align: middle;',
         },
         tdAttr: {
            style: 'vertical-align: middle;',
         },
      },
   ];

   accounts: any[] = [];

   createInvoice() {
      this.$store.commit('showLoading');
      BookingsApi.createInvoice(this.form.o_id, {
         ...this.form,
         o_invoice_to: this.form.o_agent,
         o_invoice_date: this.formatDate(this.form.o_invoice_date),
         o_payment_due: this.formatDate(this.form.o_payment_due),
         o_arrive: this.formatDate(this.form.o_arrive),
      }).then(order => {
         this.$emit('prepare-order', order);
         this.$emit('update-total-amount');
      });
   }

   updateInvoice(invoice: any[]) {
      this.$store.commit('showLoading');
      BookingsApi.updateInvoice(this.form.o_id, {
         o_invoice_id: invoice[0],
         o_paid_amount: invoice[6],
         o_paid_currency: invoice[7],
         o_invoice_state: invoice[8],
      }).then(() => {
         this.$emit('create-hash');
         this.$emit('update-total-amount');
      });
   }

   downloadInvoice(invoice: number) {
      this.$store.commit('showLoading');
      BookingsApi.downloadInvoice(this.form.o_id, invoice).then(invoice => {
         invoice.attachments.forEach(file => {
            downloadBlob(file.name, b64toBlob(file.content, 'application/pdf'));
         });
      });
   }

   @Watch('form.o_beneficiary')
   changeOBeneficiary(n: number) {
      this.$store.commit('showLoading');
      this.$store.dispatch('beneficiaries/GET_ACCOUNTS', n).then(result => {
         this.accounts = result;
         this.$emit('update-form', { o_account: result.length ? (first(result) as any).id : '' });
      });
   }
}
